@use '../../../../misc/vars'

.ColumnWrapper
  min-width: 15rem

  .Column
    display: block
    height: 9.7rem

    .Header
      text-align: center
      font-weight: bold
      color: vars.$cultured
      font-size: 2rem
      margin-bottom: .7rem
      padding-top: .25rem
      padding-bottom: .15rem

      img
        height: 4rem

    .Body
      height: 100%
      box-shadow: 0 0 0.3rem 0.3rem rgba(67, 67, 67, 50%) inset
      width: 100%
      padding: 1rem
      overflow-y: auto
      overflow-x: hidden

      .CreateButton
        background-color: vars.$eggshell
        border-radius: .7rem
        box-shadow: 0 0 .3rem .3rem rgba(67, 67, 67, 50%)
        border: none
        width: 100%
        padding: .4rem 0 .2rem 0
        margin-bottom: 1rem

        .Icon
          font-size: 2rem
          color: vars.$black-coral

  .InboxColumn
    .Header, .Body
      background-color: vars.$cadet-grey !important

  .ReadyColumn
    .Header, .Body
      background-color: vars.$maize-crayola !important

  .GoColumn
    .Header, .Body
      background-color: vars.$true-blue !important

  .DoneColumn
    .Header, .Body
      background-color: vars.$bittersweet-shimmer !important

@media (max-width: 1040px)
  .ColumnWrapper .Column
    padding-bottom: .5rem

//@media (max-height: 400px)
//  .ColumnWrapper .Column
//    margin-bottom: 5rem

@media (min-height: 450px)
  .ColumnWrapper .Column
    height: 46vh

@media (min-height: 500px)
  .ColumnWrapper .Column
    height: 51.2vh

@media (min-height: 550px)
  .ColumnWrapper .Column
    height: 55.4vh

@media (min-height: 600px)
  .ColumnWrapper .Column
    height: 59vh

@media (min-height: 650px)
  .ColumnWrapper .Column
    height: 62.8vh

@media (min-height: 700px)
  .ColumnWrapper .Column
    height: 64.8vh

@media (min-height: 720px)
  .ColumnWrapper .Column
    height: 66vh

@media (min-height: 750px)
  .ColumnWrapper .Column
    height: 67.9vh

@media (min-height: 800px)
  .ColumnWrapper .Column
    height: 70vh

@media (min-height: 850px)
  .ColumnWrapper .Column
    height: 71.5vh

@media (min-height: 900px)
  .ColumnWrapper .Column
    height: 72.9vh

@media (min-height: 950px)
  .ColumnWrapper .Column
    height: 74.5vh

@media (min-height: 1000px)
  .ColumnWrapper .Column
    height: 75.5vh

@media (min-height: 1024px)
  .ColumnWrapper .Column
    height: 76vh

@media (min-height: 1050px)
  .ColumnWrapper .Column
    height: 76.7vh

@media (min-height: 1080px)
  .ColumnWrapper .Column
    height: 77.2vh

@media (min-height: 1100px)
  .ColumnWrapper .Column
    height: 77.8vh

@media (min-height: 1200px)
  .ColumnWrapper .Column
    height: 79.6vh

@media (min-height: 1300px)
  .ColumnWrapper .Column
    height: 79.6vh

@media (min-height: 1300px)
  .ColumnWrapper .Column
    height: 81vh

@media (min-height: 1400px)
  .ColumnWrapper .Column
    height: 82.5vh

@media (min-height: 1500px)
  .ColumnWrapper .Column
    height: 83.6vh

@media (min-height: 1600px)
  .ColumnWrapper .Column
    height: 84.7vh

@media (min-height: 1700px)
  .ColumnWrapper .Column
    height: 85.7vh

@media (min-height: 1800px)
  .ColumnWrapper .Column
    height: 86.4vh

@media (min-height: 1900px)
  .ColumnWrapper .Column
    height: 87.1vh

@media (min-height: 2000px)
  .ColumnWrapper .Column
    height: 87.8vh

@media (min-height: 2160px)
  .ColumnWrapper .Column
    height: 88.7vh
