@use '../../../misc/vars'

.FormGroup
  text-align: center
  font-weight: bolder
  color: vars.$black-coral
  margin-bottom: 1.5rem !important

  .Label
    margin-bottom: 0 !important
    padding-bottom: 0 !important

  .Input
    font-size: 12.5pt !important
    min-height: 2.3rem !important
    width: 21rem !important
    text-align: center
    padding: .6rem 0 .52rem 0 !important
    background-color: vars.$light-goldenrod-yellow !important
    box-shadow: inset 0 0 .5rem .04rem #000000 !important
    border: none !important
    border-radius: .5rem !important

