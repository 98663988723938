$maize-crayola: #e2c044
$true-blue: #3e6cb4
$bittersweet-shimmer: #ba5756
$black-coral: #5f686d
$eggshell: #f4f1de
$baby-blue-eyes: #a5c6f2
$cultured: #fafafa
$cadet-grey: #8a979f
$light-goldenrod-yellow: #f5f3d2
$forest-green-web: #1F8C2F
$silver-chalice: #AAAAAA

$default-font: Helvetica, sans-serif

#ControlButton
  width: 100%
  font-family: $default-font
  font-weight: bold
  font-size: 1.3rem
  color: $maize-crayola
  border: none
  background-color: transparent
  padding-bottom: 0.1rem
  padding-top: 0.4rem
  box-shadow: none !important

  &:hover
    color: $cultured
    background-color: #365e99

  svg
    margin-right: 0.4rem

#FormWrapper
  display: flex
  justify-content: center
  align-items: center
  min-height: 100vh
  background-color: $cultured

  .Form
    background-color: $maize-crayola
    padding: 3rem 4.5rem 3rem 4.5rem
    border-radius: 1rem
    box-shadow: 0 0 0.5rem 0.01rem #000000
    max-height: 70vh
    overflow-y: auto
    overflow-x: auto

    .HeaderWrapper
      width: 100%
      display: flex
      justify-content: center
      margin-bottom: 0.5rem

      .Header
        min-height: 6rem
        margin-bottom: 1.7rem

  .ButtonWrapper
    display: flex
    justify-content: center
    width: 100%

    .Button
      font-family: $default-font
      font-weight: bold
      font-size: 14pt
      padding: 0.1rem 2.5rem 0 2.5rem
      border-color: $true-blue
      background-color: $true-blue
      color: $eggshell !important
      box-shadow: 0 0 0.3rem 0.01rem #000000
      margin-bottom: 2.2rem

  .Redirect
    width: 100%
    text-align: center
    font-family: $default-font
    font-weight: bold
    font-size: 1.2rem
    color: $black-coral
    margin-bottom: 1rem

    p
      margin-bottom: 0

      a
        color: $true-blue
        text-decoration: underline !important

.Controls
  display: inline-flex
  position: relative
  justify-content: center

  button
    @extend #ControlButton

.ControlsMenu
  top: 100%
  width: 92%
  position: absolute
  display: block
  background-color: $true-blue
  box-shadow: 0 .3rem .2rem .2rem rgba(67, 67, 67, 50%)
  max-height: 17.5rem
  overflow-y: auto
  overflow-x: hidden
  z-index: 2

  .CreateButton
    font-size: 1rem
    padding: .5rem 0 .3rem 0

.MenuItem
  .ControlButton
    font-size: 1rem
    padding: .5rem 0 .3rem 0
    position: relative

    .ActiveIcon
      font-size: 1.1rem
      position: absolute
      top: .6rem
      left: .6rem

  .react-contexify__item span
    padding: .2rem 0 0 0
    margin: 0