@use '../../../misc/vars'

.Invite
  @extend #FormWrapper
  position: fixed
  top: 0
  left: 0
  width: 100vw
  z-index: 2
  background-color: rgba(0, 0, 0, 50%)
  border: none

.Form
  cursor: default
  padding-bottom: 1rem !important
  position: relative

  .InviteHelp
    background-color: vars.$true-blue
    border: none
    border-radius: 100%
    height: 2rem
    width: 2rem
    padding: 0.3rem 0 0 0
    position: absolute
    right: 6%
    top: 59.2%
    box-shadow: 0 0 0.3rem 0.01rem #000000


    .Icon
      color: vars.$eggshell
      font-size: 1.1rem
      margin: 0

    #HelpTooltip
      font-size: 1rem
