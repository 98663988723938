@use '../../../../../../misc/vars'

.Subtask
  word-wrap: break-word

  .CheckButton
    border: none
    font-style: italic
    background-color: transparent
    color: vars.$black-coral

    .CheckBox
      margin-right: 0.2rem
