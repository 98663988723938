@use '../../../../../misc/vars'

.Task
  .TaskBody
    background-color: vars.$eggshell
    text-align: center
    padding: .75rem 0 .5rem 0
    border-radius: .7rem
    box-shadow: 0 0 .3rem .3rem rgba(67, 67, 67, 50%)
    color: vars.$black-coral
    margin-bottom: 1rem
    overflow-x: hidden

    .Title
      font-size: 1rem
      font-weight: bold
      margin-bottom: 0
      word-wrap: break-word

    .Description
      margin: .2rem .7rem 0 .7rem
      font-size: 0.9rem
      flex: 1
      flex-wrap: wrap
      word-wrap: break-word

    .Subtasks
      margin-top: .5rem
      border-top: .2rem solid vars.$black-coral
      padding: .5rem 1rem 0 1rem
      list-style: none
      margin-bottom: 0
      word-wrap: break-word

    .AssignedUser
      background-color: vars.$silver-chalice
      color: vars.$eggshell
      margin-top: .5rem
      padding: 0 1rem 0 1rem
      list-style: none
      margin-bottom: 0
      word-wrap: break-word

    .Me
      background-color: vars.$forest-green-web !important

