@use '../../../misc/vars'

.CreateBoard
  @extend #FormWrapper
  position: fixed
  top: 0
  left: 0
  width: 100vw
  z-index: 2
  background-color: rgba(0, 0, 0, 50%)
  border: none

  .Form
    cursor: default
    padding-bottom: 1rem !important

    .ButtonWrapper
      padding-top: 1rem
      width: 110%

      .ButtonCol
        display: flex
        justify-content: center

        .Button
          padding: .1rem 2rem 0 2rem

        .CancelButton
          background-color: vars.$black-coral
          border-color: vars.$black-coral
          width: 100%

        .GoButton
          width: 100%
