@use '../../../misc/vars'

#Header
  min-width: 65rem

  .Logo
    display: flex
    justify-content: center
    background-color: vars.$maize-crayola
    width: 100%
    min-width: 50rem
    height: 4.9rem
    top: 0

    img
      top: 0.5rem
      position: absolute
      min-height: 5.2rem

  .ControlsWrapper
    background-color: vars.$true-blue
    margin-bottom: 1rem
    box-shadow: 0 .3rem .4rem 0.001rem rgba(67, 67, 67, 50%)
    width: 100%
    min-width: 50rem
    position: relative
    height: 2.2rem
    display: flex
    align-items: center

    .ControlsRow
      min-width: 40rem !important

      .LogoutButton
        background-color: transparent
        border: none
        color: vars.$maize-crayola
        position: absolute
        left: 0
        height: 100%
        padding-right: .5rem
        display: flex
        align-items: center
        justify-content: center
        &:hover
          color: vars.$cultured
          background-color: #365e99

        .Icon
          font-size: 1.8rem
          padding-top: 0.32rem
          padding-bottom: 0.2rem

      .UserButton
        background-color: transparent
        border: none
        color: vars.$maize-crayola
        position: absolute
        right: 0
        height: 100%
        padding-right: 0.7rem
        display: flex
        align-items: center
        justify-content: center
        &:hover
          color: vars.$cultured
          background-color: #365e99


        .Icon
          font-size: 1.8rem
          padding-top: 0.32rem
          padding-bottom: 0.2rem
