@use '../../../misc/vars'

.Help
  display: flex
  justify-content: center
  align-items: center
  min-height: 100vh
  background-color: rgba(0, 0, 0, 50%)
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  text-align: center
  z-index: 1
  color: vars.$black-coral

  .Body
    position: absolute
    background-color: vars.$maize-crayola
    padding: 3rem 4.5rem 3rem 4.5rem
    box-shadow: 0 0 0.5rem 0.01rem #000000
    border-radius: 1rem
    z-index: 2
    cursor: default

    .HeaderWrapper
      width: 100%
      display: flex
      justify-content: center
      margin-bottom: 0.5rem

      .Header
        min-height: 6rem
        margin-bottom: 1.7rem

    h1
      color: vars.$black-coral
      font-size: 1.4rem
      font-weight: bold
      margin-bottom: 0

    .Button
      margin-top: 1rem
      font-weight: bold
      font-size: 14pt
      padding: 0.1rem 2.5rem 0 2.5rem
      border-color: vars.$black-coral
      background-color: vars.$black-coral
      color: vars.$eggshell !important
      box-shadow: 0 0 0.3rem 0.01rem #000000

    ol
      list-style-position: inside

      li
        margin-left: 0

